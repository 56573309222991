<template>
  <v-card class="mx-2" flat :loading="loading">
    <v-card flat>
      <v-card-text>
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="4">
              <v-combobox
                v-model="selectedBanco"
                :items="bancos"
                :label="$tc('global.banco', 2)"
                dense
                outlined
                :rules="formRules"
                :loading="loadingBancos"
                required
                item-text="descricao"
                item-value="id"
              ></v-combobox>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="cheque.conta"
                :rules="formRules"
                :label="$tc('global.conta')"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="cheque.numero"
                :label="$tc('global.numero') + ' - ' + $tc('global.cheque')"
                :rules="formRules"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="selectedTipo"
                :label="$tc('global.tipo')"
                :items="tipos"
                dense
                :rules="formRules"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="cheque.emissor_nome"
                :label="$tc('global.emitidoPor')"
                :rules="formRules"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="cheque.emissor_ci"
                :label="$tc('global.documento') + ' ' + $tc('global.emissor')"
                :rules="formRules"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <DataField
                hide_details
                :disabled="selectedTipo == 'A VISTA'"
                :data_sync.sync="data_emissao"
                :label="$tc('global.data-emissao')"
                :obrigatorio="formRules ? true : false"
              />
            </v-col>

            <v-col cols="12" md="6">
              <DataField
                hide_details
                :data_sync.sync="cheque.data_vencimento"
                :label="$tc('global.data-vencimento')"
                :obrigatorio="formRules ? true : false"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          :disabled="chequeValidado"
          class="button_2 white--text"
          @click="addCheque"
        >
          <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
          {{ $tc("global.validar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { fetchBancos } from "@/api/bancos/bancos.js";
import { chequeIsValid } from "@/api/cheques/cheques.js";
import { format } from "date-fns";

export default {
  name: "BaixaCheque",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  data() {
    return {
      cheque: {},
      tipos: ["A VISTA", "DIFERIDO"],
      bancos: [],
      validForm: true,
      loading: false,
      formRules: [inputRequired],
      loadingBancos: true,
      selectedBanco: null,
      chequeValidado: false,
      selectedTipo: null,
      data_emissao: null,
    };
  },

  watch: {
    selectedBanco() {
      if (this.selectedBanco) {
        this.cheque.banco_id = this.selectedBanco.id;
      }
    },
    selectedTipo() {
      if (this.selectedTipo) {
        this.cheque.tipo = this.selectedTipo;
        if (this.selectedTipo == "A VISTA") {
          this.data_emissao = format(new Date(), "yyyy-MM-dd");
        }
      }
    },
    data_emissao() {
      if (this.data_emissao) {
        this.cheque.data_emissao = this.data_emissao;
      }
    },
  },

  methods: {
    getBancos() {
      this.loadingBancos = true;
      return fetchBancos()
        .then((response) => {
          this.bancos = response;
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingBancos = false;
        });
    },

    addCheque() {
      if (this.$refs.form.validate()) {
        let cheque2 = {};

        cheque2.banco_id = this.cheque.banco_id;
        cheque2.conta = this.cheque.conta;
        cheque2.numero = this.cheque.numero;
        cheque2.tipo = this.cheque.tipo;
        cheque2.emissor_nome = this.cheque.emissor_nome;
        cheque2.emissor_ci = this.cheque.emissor_ci;
        cheque2.data_emissao = this.cheque.data_emissao;
        cheque2.data_vencimento = this.cheque.data_vencimento;

        chequeIsValid(cheque2)
          .then((response) => {
            if (response.status === 200) {
              this.$emit("update:cheque", cheque2);
              this.$emit("update:allowCheque", true);
              this.chequeValidado = true;
            }
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error(this.$tc("global.chequeNumeroExistente"));
            }
          });
      }
    },
  },

  async mounted() {
    await this.getBancos();
  },
};
</script>

<style></style>
